<template>
  <div class="quote-header tile is-ancestor is-parent">
    <quote-customer v-if="innerCustomer"
      v-model="innerCustomer"
      :quote.sync="innerValue"
      @select-customer="selectCustomer" />
    <!-- <div class="is-divider-vertical px-0" /> -->
    <quote-insurer v-if="innerInsurer"
      v-model="innerInsurer"
      :quote.sync="innerValue"
      :vehicle="innerVehicle"
      @select-insurer="selectInsurer"
      :next-line-number="nextLineNumber" />
    <!-- <div class="is-divider-vertical px-0" /> -->
    <quote-vehicle v-if="innerVehicle"
      v-model="innerVehicle"
      :quote.sync="innerValue"
      @select-vehicle="selectVehicle" />
  </div>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import { QuoteVehicle, QuoteCustomer, QuoteInsurer } from './components/QuoteHeader'
import storeMixin from './storeMixin'
import { EventHubTypes } from '@/enums'
import DeepDiif from 'deep-diff'

export default {
  name: 'QuoteHeader',
  inject: ['$vv'],
  components: { QuoteVehicle, QuoteCustomer, QuoteInsurer },
  mixins: [storeMixin],
  props: {
    value: null,
    vehicle: null,
    customer: null,
    insurer: null,
    nextLineNumber: null
  },
  data: () => {
    return {
      innerValue: null,
      innerVehicle: null,
      innerCustomer: null,
      innerInsurer: null,
      isPauseWatch: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        const diff = DeepDiif.diff(oldVal, newVal)
        if (!this.isPauseWatch && diff) {
          this.$emit('input', newVal)
          this.$nextTick(() => {
            this.$eventHub.$emit(`${EventHubTypes.EntitySaved}-${this.$options.name}`)
          })
        }
      },
      deep: true
    },
    innerVehicle: {
      handler: function (newVal, oldVal) {
        if (!this.isPauseWatch) {
          this.$emit('update:vehicle', newVal)
        }
      },
      deep: true
    },
    innerCustomer: {
      handler: function (newVal, oldVal) {
        if (!this.isPauseWatch) {
          this.$emit('update:customer', newVal)
        }
      },
      deep: true
    },
    innerInsurer: {
      handler: function (newVal, oldVal) {
        if (!this.isPauseWatch) {
          this.$emit('update:insurer', newVal)
        }
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        if (!this.isPauseWatch) {
          this.innerValue = _cloneDeep(newVal)
        }
      },
      deep: true
    }
  },
  created() {
    this.isPauseWatch = true
    this.innerValue = _cloneDeep(this.value)
    this.innerVehicle = _cloneDeep(this.vehicle)
    this.innerCustomer = _cloneDeep(this.customer)
    this.innerInsurer = _cloneDeep(this.insurer)
    this.isPauseWatch = false
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, this.updateInnerObjects)
    // this.$vv.quoteHeaderGroup.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    updateInnerObjects() {
      this.isPauseWatch = true
      console.log(`${this.$options.name} - ${this.value.modifiedDate}`)
      console.log(`${this.$options.name} - ${this.vehicle.modifiedDate}`)
      console.log(`${this.$options.name} - ${this.customer.modifiedDate}`)
      console.log(`${this.$options.name} - ${this.insurer.modifiedDate}`)
      this.innerValue = _cloneDeep(this.value)
      this.innerVehicle = _cloneDeep(this.vehicle)
      this.innerCustomer = _cloneDeep(this.customer)
      this.innerInsurer = _cloneDeep(this.insurer)
      this.isPauseWatch = false
      this.$nextTick(() => {
        this.$eventHub.$emit(`${EventHubTypes.EntitySaved}-${this.$options.name}`)
      })
    },
    selectVehicle(vehicleId, customerId) {
      this.$emit('select-vehicle', vehicleId, customerId)
    },
    selectInsurer(id) {
      this.$emit('select-insurer', id)
    },
    selectCustomer(id) {
      this.$emit('select-customer', id)
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.is-divider-vertical::before {
  top: 0 !important;
  bottom: 0 !important;
}
</style>