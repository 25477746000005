<template>
  <div v-if="value"
    class="tile is-parent pl-0">
    <article class="tile is-child box">
      <div class="columns mb-0">
        <div class="column is-flex is-align-items-center">
          <i class="icon mdi mdi-shield-outline mdi-24px" />
          <p class="title pl-1">Insurer/Debtor</p>
        </div>

      </div>
      <div class="is-divider" />
      <div class="field">
        <label class="label is-required-label">Insurer</label>
        <div class="control"
          :title="isAdditionalQuote ? 'Insurer can only be changed on the main quote' : ''">
          <input v-if="readOnlyView"
            class="input is-static"
            :value="quoteInsurer ? quoteInsurer.name : ''"
            readonly>
          <multiselect v-else-if="!readOnlyView && insurers"
            :class="[ $v.insurer.insurerId.$error || $v.insurer.name.$error ? 'is-invalid' : 'is-required-field' ]"
            v-model="selectedInsurer"
            deselect-label="Can't remove this value"
            track-by="assetId"
            label="assetName"
            placeholder="Search By Name..."
            :options="insurers"
            :loading="isInsurerLoading"
            :internal-search="false"
            :options-limit="insurerFilter.pageSize"
            @select="selectInsurer"
            @remove="removeInsurer"
            @search-change="getInsurerDropdownFilter"
            :searchable="true"
            :show-labels="false"
            :allow-empty="true"
            :disabled="innerQuote.readOnly || audanetLock || isAdditionalQuote">
            <template slot="option"
              slot-scope="props">
              <span>{{ props.option.assetName }}</span>
            </template>
            <span class="has-text-danger"
              slot="noResult">Insurer not found.</span>
          </multiselect>
        </div>
      </div>
      <div class="field">
        <label class="label">ABN</label>
        <div class="control has-icons-right"
          :title="isAdditionalQuote ? 'Insurer can only be changed on the main quote' : ''">
          <the-mask type="text"
            class="input"
            placeholder="ABN"
            v-model="innerInsurer.abn"
            :mask="['## ### ### ###']"
            :class="{'is-danger' : $v.insurer.abn.$error, 'disabled' : isAdditionalQuote}"
            @input.native="$v.insurer.abn.$touch()" />
          <span v-if="!$v.insurer.abn.$error && innerInsurer.abn"
            class="icon has-text-success is-right">
            <i class="mdi mdi-check mdi-18px" />
          </span>
          <span class="help is-danger"
            v-if="!$v.insurer.abn.minLength">ABN must be at least {{ $v.insurer.abn.$params.minLength.min }} letters.</span>
          <span class="help is-danger"
            v-else-if="!$v.insurer.abn.validAbn">ABN format is invalid.</span>
        </div>
      </div>
      <div class="columns field">
        <div class="column">
          <div class="field">
            <label class="label">Method</label>
            <div class="buttons has-addons">
              <button class="button"
                :class="{ 'has-text-weight-semibold has-text-primary is-selected': innerQuote.quotingMethod === quotingMethodTypes.Hour, 'disabled' : isAdditionalQuote}"
                @click="innerQuote.quotingMethod = quotingMethodTypes.Hour"
                :disabled="innerQuote.readOnly || audanetLock || (quote.quoteAssessments && quote.quoteAssessments.length > 0)">
                <span class="icon">
                  <i class="icon mdi mdi-clock-outline mdi-20px"
                    :class="{ 'has-text-weight-bold has-text-primary': innerQuote.quotingMethod === quotingMethodTypes.Hour }" />
                </span>
                <span>Hours</span>
              </button>
              <button class="button"
                :class="{ 'has-text-weight-semibold has-text-primary is-selected': innerQuote.quotingMethod === quotingMethodTypes.Dollar }"
                @click="innerQuote.quotingMethod = quotingMethodTypes.Dollar"
                :disabled="innerQuote.readOnly || audanetLock || (quote.quoteAssessments && quote.quoteAssessments.length > 0)">
                <span class="icon">
                  <i class="icon mdi mdi-cash-multiple mdi-20px"
                    :class="{ 'has-text-weight-bold has-text-primary': innerQuote.quotingMethod === quotingMethodTypes.Dollar }" />
                </span>
                <span>Dollars</span>
              </button>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Assessor</label>
            <div class="control"
              :class="{'disabled' : isAdditionalQuote}">
              <input class="input is-capitalized"
                :class="{ 'is-static' : readOnlyView }"
                v-model="innerQuote.assessorName"
                type="text"
                :placeholder="!readOnlyView ? 'Assessor' : ''">
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-body">
          <div class="field">
            <label class="label">Claim Number</label>
            <div class="control"
              :class="{'disabled' : isAdditionalQuote}">
              <input v-model="innerQuote.claimNo"
                :class="{ 'is-invalid' : $v.entity.claimNo.$error }"
                content="Required by ORM."
                v-tippy="{ theme: 'danger', enabled: $v.entity.claimNo.$error }"
                class="input"
                type="text"
                placeholder="Claim Number">
            </div>
          </div>
          <div class="field">
            <label class="label">Policy Number</label>
            <div class="control">
              <input v-model="innerQuote.policyNo"
                class="input"
                type="text"
                placeholder="Policy No.">
            </div>
          </div>
        </div>
      </div>
      <div class="columns field mb-0">
        <div class="column">
          <div class="field">
            <label class="label">Labour Times</label>
            <div class="control is-expanded"
              :class="{'disabled' : isAdditionalQuote}">
              <input v-if="readOnlyView"
                class="input is-static"
                :value="innerQuote.labourType"
                readonly>
              <div v-else
                class="select is-fullwidth">
                <select v-model="innerQuote.labourType"
                  class="select-width"
                  :disabled="innerQuote.readOnly || audanetLock">
                  <option v-for="(labourType, propertyName, index) in labourTimesTypeEnum"
                    :key="index"
                    :value="propertyName">
                    {{ labourType }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Authorised Date</label>
            <div class="control">
              <v-date-picker v-model="selectedAuthorisedDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="authorisedDateInputEvent"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Authorised Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false"
        class="field">
        <div class="field-body">
          <div class="field">
            <label class="label">ABN</label>
            <div class="control has-icons-right">
              <the-mask type="text"
                class="input"
                placeholder="ABN"
                v-model="innerInsurer.abn"
                :mask="['## ### ### ###']"
                :class="{'is-invalid' : $v.insurer.abn.$error}" />
              <span v-if="!$v.insurer.abn.$error && innerInsurer.abn"
                class="icon has-text-success is-right">
                <i class="mdi mdi-check mdi-18px" />
              </span>
              <span class="help is-danger"
                v-if="!$v.insurer.abn.minLength">ABN must be at least {{ $v.insurer.abn.$params.minLength.min }} letters.</span>
              <span class="help is-danger"
                v-else-if="!$v.insurer.abn.validAbn">ABN format is invalid.</span>
            </div>
          </div>
          <div class="field">
            <label class="label">ARBN</label>
            <div class="control has-icons-right">
              <the-mask type="text"
                class="input"
                placeholder="ARBN"
                v-model="innerInsurer.arbn"
                :mask="['### ### ###']"
                :class="{'is-invalid' : $v.insurer.arbn.$error}" />
              <span v-if="!$v.insurer.arbn.$error && innerInsurer.arbn"
                class="icon has-text-success is-right">
                <i class="mdi mdi-check mdi-18px" />
              </span>
              <span class="help is-danger"
                v-if="!$v.insurer.arbn.minLength">ARBN must be at least {{ $v.insurer.arbn.$params.minLength.min }} letters.</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false"
        class="field">
        <div class="field-body">
          <div class="field">
            <label class="label">BSB</label>
            <div class="control">
              <the-mask type="text"
                class="input"
                placeholder="BSB"
                v-model="innerInsurer.bsb"
                :mask="['###-###']" />
            </div>
          </div>
          <div class="field">
            <label class="label">Account No.</label>
            <div class="control">
              <input v-model="innerInsurer.accoutNo"
                class="input uppercase"
                type="text"
                placeholder="Account No.">
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="field-body">
          <div class="field">
            <div class="is-flex is-justify-content-space-between">
              <label class="label">Excess</label>
              <div class="is-flex is-align-items-center is-justify-content-end mb-2">
                <label class="pretty-checkbox-label pr-1 is-size-7">Includes GST</label>
                <div class="pretty p-icon p-round p-smooth m-0 is-size-7">
                  <input v-model="innerQuote.excessWithGst"
                    id="chk-excessWithGst"
                    type="checkbox"
                    :disabled="innerQuote.readOnly || readOnlyView || audanetLock">
                  <div class="state p-primary">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>
            </div>
            <div class="control"
              :class="{'disabled' : isAdditionalQuote}">
              <input v-if="readOnlyView"
                class="input is-static"
                :value="excess | formatNumber($userInfo.locale)"
                readonly>
              <vue-numeric v-else
                class="input has-text-right"
                v-model="excess"
                @blur="calculateExcess()"
                :min="0"
                placeholder="Excess"
                :precision="2"
                :disabled="innerQuote.readOnly || audanetLock" />
            </div>
          </div>
          <div class="field">
            <label class="label">Owners Contribution</label>
            <div class="control">
              <input v-if="readOnlyView"
                class="input is-static"
                :value="innerQuote.ownersContribution | formatNumber($userInfo.locale)"
                readonly>
              <vue-numeric v-else
                class="input has-text-right"
                v-model="innerQuote.ownersContribution"
                :min="0"
                placeholder="Owner's Contribution"
                :precision="2"
                :disabled="innerQuote.readOnly || audanetLock || innerQuote.subQuoteNo > 0" />
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Discount On Invoice</label>
        <div class="control"
          :class="{'disabled' : isAdditionalQuote}">
          <div v-if="readOnlyView"
            class="control">
            <input class="input is-static"
              :value="innerQuote.discount | formatNumber($userInfo.locale)"
              readonly>
          </div>
          <vue-numeric v-else
            class="input has-text-right"
            v-model="innerQuote.discount"
            :min="0"
            formats
            placeholder="Discount applied on invoice"
            :precision="2"
            :symbol="$company.currency.symbol"
            :disabled="innerQuote.readOnly || audanetLock" />
        </div>
      </div>
      <div v-show="innerInsurer.damageSeverity"
        class="field">
        <label class="label">Damage Severity</label>
        <div class="control">
          <div class="select is-fullwidth"
            :class="{'is-invalid' : $v.entity.damageSeverity.$error}">
            <select v-model="innerQuote.damageSeverity"
              class="select-width"
              :disabled="innerQuote.readOnly">
              <option v-for="(damageSeverityType, propertyName, index) in damageSeverityTypes"
                :key="index"
                :value="damageSeverityType">
                {{ getDamageSeverityDescription(propertyName) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="field"
        v-if="includeClaimsType">
        <div class="control is-expanded">
          <multiselect v-model="selectedClaimsType"
            :options="claimsTypes"
            :preserve-search="true"
            track-by="key"
            @select="onClaimsTypeChange"
            :internal-search="true"
            :searchable="true"
            :show-labels="false"
            placeholder="Select Claims type"
            label="description"
            :allow-empty="true">
            <span class="has-text-danger"
              slot="noResult">Claims type not found.</span>
          </multiselect>
          <!-- </div> -->
        </div>
      </div>

    </article>
  </div>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import _debounce from 'lodash.debounce'
import Guid from '@/components/Guid'
import { QuoteService, InsurerService, QuoteTemplateService, CompanyService } from '@/services'
import { LabourTimeTypes, EcomTypes, EventHubTypes, ItemCategoryTypes, QuotingMethodTypes, DamageSeverityTypes } from '@/enums'
import VueNumeric from '@/components/VueNumeric'
import Multiselect from 'vue-multiselect'
import { QuoteItemModel } from '@/classes/viewmodels'
import { GstModel } from '@/classes'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import StoreMixin from '../../storeMixin'
import DeepDiif from 'deep-diff'

export default {
  name: 'QuoteInsurer',
  inject: ['$vv'],
  components: {
    VueNumeric,
    Multiselect
  },
  mixins: [StoreMixin],
  props: {
    value: null,
    quote: null,
    vehicle: null,
    nextLineNumber: null
  },
  data: () => {
    return {
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      innerInsurer: null,
      innerQuote: null,
      quoters: [],
      selectedAuthorisedDate: null,
      isInsurerLoading: false,
      selectedInsurer: null,
      insurerFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: false,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      insurers: [],
      arnieBrands: null,
      isQuoteWatchPaused: false,
      isInsurerWatchPaused: false,
      excess: 0,
      claimsTypes: []
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    labourTimesTypeEnum() {
      return LabourTimeTypes
    },
    damageSeverityTypes() {
      return DamageSeverityTypes
    },
    ecomType: {
      get: function () {
        if (this.innerInsurer.pnet) {
          return this.ecomTypeEnum.PNET
        } else if (this.innerInsurer.orm) {
          return this.ecomTypeEnum.ORM
        } else {
          return this.ecomTypeEnum.None
        }
      },
      set: function (newValue) {
        if (newValue === this.ecomTypeEnum.None) {
          this.innerInsurer.pnet = false
          this.innerInsurer.orm = false
        } else if (newValue === this.ecomTypeEnum.PNET) {
          this.innerInsurer.pnet = true
          this.innerInsurer.orm = false
          this.innerQuote.excessGST = true
          this.innerQuote.quotingMethod = 'D'
        } else if (newValue === this.ecomTypeEnum.ORM) {
          this.innerInsurer.pnet = false
          this.innerInsurer.orm = true
          this.innerQuote.excessGST = false
          this.innerQuote.quotingMethod = 'H'
        }
      }
    },
    ecomTypeEnum: function () {
      return EcomTypes
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    includeClaimsType() {
      return this.$company && this.$company.info.countryCode === 'MY'
    },
    selectedClaimsType() {
      return this.claimsTypes.length ? this.claimsTypes.find((i) => i.key === this.quote.claimsTypeKey) : {}
    },
    isAdditionalQuote() {
      return !!this.quote?.subQuoteNo
    }
  },
  watch: {
    selectedAuthorisedDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerQuote.authorisedDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerQuote.authorisedDate = null
      }
    },
    innerInsurer: {
      handler: function (newVal, oldVal) {
        if (DeepDiif(newVal, oldVal)) {
          this.$emit('input', newVal)
        }
      },
      deep: true
    },
    innerQuote: {
      handler: function (newVal, oldVal) {
        if (!this.isQuoteWatchPaused) {
          this.$emit('update:quote', newVal)
        }
      },
      deep: true
    },
    'innerQuote.quotingMethod': function (newVal, oldVal) {
      if (oldVal) {
        const vm = this
        // Recalculates labours
        this.innerQuote.labours.forEach(function (item, index) {
          if (vm.innerQuote.quotingMethod === QuotingMethodTypes.Hour) {
            if (item.rev !== '1') {
              const rate = vm.getRate(item.itemType)
              item.rate = rate
            }
            item.hourValue = item.rate !== 0 ? roundAwayFromZero(item.dollarValue / item.rate) : 0
            item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
          } else {
            item.hourValue = item.dollarValue
            // item.rate = 1
          }
        })
        // Recalculates other labours
        this.innerQuote.others.forEach(function (item, index) {
          if (vm.innerQuote.quotingMethod === QuotingMethodTypes.Hour) {
            if (item.rev !== '1') {
              const rate = vm.getRate(item.itemType)
              item.rate = rate
            }
            item.hourValue = item.rate !== 0 ? roundAwayFromZero(item.dollarValue / item.rate) : 0
            item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
          } else {
            item.hourValue = item.dollarValue
          }
        })
        // Recalculates OPG
        this.innerQuote.opgs.forEach(function (item, index) {
          if (item.rev !== '1') {
            const rate = vm.getRate(item.opgCode)
            item.rate = rate
          }
          if (vm.innerQuote.quotingMethod === QuotingMethodTypes.Hour) {
            item.hourValue = item.rate !== 0 ? roundAwayFromZero(item.dollarValue / item.rate) : 0
            item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
          } else {
            item.hourValue = item.dollarValue
          }
        })
      }
    }
  },
  created() {
    if (!!this.includeClaimsType) {
      this.getClaimsTypes()
    }

    this.isQuoteWatchPaused = true
    this.isInsurerWatchPaused = true
    this.innerInsurer = _cloneDeep(this.value)
    this.innerQuote = _cloneDeep(this.quote)
    this.excess = this.innerQuote.excess
    if (!this.innerInsurer.isNew) {
      this.insurerFilter.assetId = this.innerInsurer.insurerId
    }
    this.getInsurerDropdown()
    this.getArnieBrands()
    if (this.innerQuote.authorisedDate) {
      this.selectedAuthorisedDate = new Date(`${this.innerQuote.authorisedDate}`)
    }
    this.isQuoteWatchPaused = false
    this.isInsurerWatchPaused = false
  },
  mounted() {
    this.$eventHub.$on(`${EventHubTypes.EntitySaved}-${this.$parent.$options.name}`, this.updateInnerInsurer)
    // this.$vv.insurer.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(`${EventHubTypes.EntitySaved}-${this.$parent.$options.name}`)
  },
  methods: {
    async getArnieBrands() {
      this.arnieBrands = await InsurerService.getArnieBrand()
    },
    onChangeInsurerBrand(event) {
      this.innerInsurer.arnie = this.innerInsurer.insurerBrand !== '' && this.innerInsurer.insurerBrand !== '0'
    },
    authorisedDateInputEvent(event) {
      this.selectedAuthorisedDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    },
    async getInsurerDropdown() {
      this.isInsurerLoading = true
      this.insurers = await QuoteService.getInsurerDropdownProto(this.insurerFilter)
      if (this.insurerFilter.assetId !== Guid.empty()) {
        this.selectedInsurer = this.insurers.find((c) => c.assetId === this.innerInsurer.insurerId)
      }
      this.isInsurerLoading = false
    },
    getInsurerDropdownFilter: _debounce(async function (query) {
      this.isInsurerLoading = true
      this.insurerFilter.assetName = query
      this.insurers = await QuoteService.getInsurerDropdownProto(this.insurerFilter)
      this.isInsurerLoading = false
    }, 500),
    async selectInsurer(selected) {
      if (!selected) {
        return
      }

      // Update quote
      // this.innerQuote.insurerId = selected.assetId
      this.$emit('select-insurer', selected.assetId)
      // const isItemsEmpty =
      //   this.innerQuote.labours.length === 0 &&
      //   this.innerQuote.parts.length === 0 &&
      //   this.innerQuote.others.length === 0 &&
      //   this.innerQuote.opgs.length === 0 &&
      //   this.innerQuote.miscs.length === 0 &&
      //   this.innerQuote.sublets.length === 0
      // if (this.innerInsurer.isNew) {
      //   this.innerInsurer.miscs.splice(0, this.innerInsurer.miscs.length)
      // }
      // if (isItemsEmpty || this.innerInsurer.isNew) {
      // Update insurer related values when there are no items
      // this.$showSpinner()
      // Propagate to parent QuoteView to reload customer and vehicle
      // await this.getQuoteInsurer({ id: selected.assetId, refresh: true })
      // this.$hideSpinner()

      // Update quote
      // this.innerQuote.insurerId = selected.assetId
      // this.$emit('select-insurer', selected.assetId)

      // this.innerQuote.newMarkup = this.quoteInsurer.newMarkup
      // this.innerQuote.usedMarkup = this.quoteInsurer.usedMarkup
      // this.innerQuote.recoreMarkup = this.quoteInsurer.recoreMarkup
      // this.innerQuote.afterMarketMarkup = this.quoteInsurer.afterMarketMarkup
      // this.innerQuote.exchangeMarkup = this.quoteInsurer.exchangeMarkup
      // this.innerQuote.reconditionedMarkup = this.quoteInsurer.reconditionedMarkup
      // this.innerQuote.shopRate = this.quoteInsurer.shopRate
      // this.innerQuote.labourType = this.quoteInsurer.labourType
      // this.innerQuote.verRtrm = Math.max.apply(
      //   Math,
      //   this.quoteInsurer.insurerConsumables.map(function (c) {
      //     return c.versionNo
      //   })
      // )
      // // Update quote rates
      // this.addInsurerLabourRates(this.quoteInsurer)
      // // Add or update automatic items to quote
      // this.addInsurerAutoItems(this.quoteInsurer)
      // // Add or update template items to quote
      // this.addInsurerTemplateItems(this.quoteInsurer)
      // }
      this.innerInsurer.insurerId = selected.assetId
      this.insurerFilter.assetId = selected.assetId
      this.insurerFilter.assetName = ''
      this.insurers = await QuoteService.getInsurerDropdownProto(this.insurerFilter)
    },
    removeInsurer() {
      this.insurerFilter.assetId = Guid.empty()
      this.innerInsurer.insurerId = Guid.empty()
      this.getInsurerDropdown()
    },
    addInsurerLabourRates(insurer) {
      const vm = this
      const isInsurerNtar =
        insurer.labourType === LabourTimeTypes.NTAR || insurer.labourType === LabourTimeTypes.LTAR || insurer.labourType === LabourTimeTypes.eMTA
      insurer.insurerLabourRates.forEach(function (ir) {
        const index = vm.innerQuote.rates.findIndex((qr) => qr.labourCodeId === ir.labourCode)
        if (index > -1) {
          vm.innerQuote.rates[index].rate = isInsurerNtar ? insurer.shopRate : ir.rate
        } else {
          const labourType = vm.$labourTypes.find((t) => t.labourTypeCode === ir.labourType)
          const newRate = {
            rate: isInsurerNtar ? insurer.shopRate : ir.rate,
            quoteVersion: 0,
            quoteId: vm.innerQuote.quoteId,
            labourCodeId: ir.labourCode,
            labourTypeId: labourType.labourTypeId,
            deleted: false,
            createdBy: '',
            createdDate: '',
            modifiedBy: '',
            modifiedDate: '',
            isNew: true,
            isDeleted: false
          }
          vm.innerQuote.rates.splice(vm.innerQuote.rates.length, 1, newRate)
        }
      })
    },
    addInsurerAutoItems(insurer) {
      if (insurer.insurerAutoItems && insurer.insurerAutoItems.length > 0) {
        const vm = this
        let lineNumber = this.nextLineNumber
        insurer.insurerAutoItems.forEach(function (autoItem) {
          let currentItem = vm.innerQuote.miscs.find((i) => i.itemDesc === autoItem.itemDesc && i.itemNo === autoItem.itemNo)
          if (!currentItem) {
            let newItem = new QuoteItemModel(vm.innerQuote.quoteId, autoItem.itemNo, autoItem.itemDesc, ItemCategoryTypes.MISC)
            newItem.value = autoItem.price
            newItem.reportOnly = autoItem.reportOnly
            newItem.sortNo = vm.innerQuote.miscs.length ? Math.max(...vm.innerQuote.miscs.map((i) => i.sortNo)) + 1 : 1
            newItem.lineNumber = lineNumber
            vm.innerQuote.miscs.splice(vm.innerQuote.miscs.length, 1, newItem)
            lineNumber++
            vm.innerQuote.lines++
          } else {
            currentItem.value = autoItem.price
            currentItem.reportOnly = autoItem.reportOnly
          }
        })
      }
    },
    async addInsurerTemplateItems(insurer) {
      if (Guid.validGuid(insurer.templateId)) {
        const templateItems = await QuoteTemplateService.getQuoteTemplateItems(insurer.templateId)
        if (templateItems && templateItems.length > 0) {
          const vm = this
          templateItems.forEach(function (templateItem) {
            let currentItem = vm.innerQuote.miscs.find(
              (i) => i.itemDesc === templateItem.itemDesc && i.itemNo === templateItem.itemNo && i.itemType === templateItem.itemType
            )
            if (!currentItem) {
              let newItem = new QuoteItemModel(vm.innerQuote.quoteId, templateItem.itemNo, templateItem.itemDesc, templateItem.itemType)
              newItem.value = templateItem.value
              newItem.reportOnly = templateItem.reportOnly
              newItem.sortNo = templateItem.sortNo
              // let sortNo
              switch (templateItem.itemType) {
                case ItemCategoryTypes.RR:
                case ItemCategoryTypes.REP:
                case ItemCategoryTypes.RWA:
                  newItem.rate = vm.getLabourRate(templateItem.itemType)
                  vm.innerQuote.labours.splice(vm.innerQuote.labours.length, 1, newItem)
                  break
                case ItemCategoryTypes.PAINT:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.innerQuote.labours.splice(vm.innerQuote.labours.length, 1, newItem)
                  break
                case ItemCategoryTypes.OPG:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.innerQuote.opgs.splice(vm.innerQuote.opgs.length, 1, newItem)
                  break
                case ItemCategoryTypes.MECH:
                case ItemCategoryTypes.CD:
                case ItemCategoryTypes.CRUSH:
                case ItemCategoryTypes.FIBER:
                  newItem.rate = vm.getLabourRate(vm.quoteVehicle.paintGroup)
                  vm.innerQuote.others.splice(vm.innerQuote.others.length, 1, newItem)
                  break
                case ItemCategoryTypes.PART:
                  vm.innerQuote.parts.splice(vm.innerQuote.parts.length, 1, newItem)
                  break
                case ItemCategoryTypes.MISC:
                  vm.innerQuote.miscs.splice(vm.innerQuote.miscs.length, 1, newItem)
                  break
                case ItemCategoryTypes.SUBL:
                  vm.innerQuote.sublets.splice(vm.innerQuote.sublets.length, 1, newItem)
                  break
              }
              // newItem.sortNo = templateItem.sortNo // || sortNo
            } else {
              currentItem.value = templateItem.value
              currentItem.itemType = templateItem.itemType
              currentItem.reportOnly = templateItem.reportOnly
              currentItem.sortNo = templateItem.sortNo
            }
          })
        }
      }
    },
    getLabourRate(code) {
      if (!this.innerQuote.rates) {
        return {}
      }
      let rate = this.isNtar ? this.innerQuote.rates[0] : this.innerQuote.rates.find((i) => i.labourCodeId === code)
      return !rate ? 0 : rate.rate
    },
    updateInnerInsurer() {
      console.log(`${this.$options.name} - ${this.value.modifiedDate}`)
      console.log(`${this.$options.name} - ${this.quote.modifiedDate}`)
      this.isQuoteWatchPaused = true
      this.isInsurerWatchPaused = true
      this.innerInsurer = _cloneDeep(this.value)
      this.innerQuote = _cloneDeep(this.quote)
      // this.updateQuote()
      this.$nextTick(() => {
        this.isQuoteWatchPaused = false
        this.isInsurerWatchPaused = false
      })
    },
    updateQuote() {
      console.log('QuoteInsurer.updateQuote')
      const isItemsEmpty =
        this.innerQuote.labours.length === 0 &&
        this.innerQuote.parts.length === 0 &&
        this.innerQuote.others.length === 0 &&
        this.innerQuote.opgs.length === 0 &&
        this.innerQuote.miscs.length === 0 &&
        this.innerQuote.sublets.length === 0

      if (isItemsEmpty || this.innerInsurer.isNew) {
        this.innerQuote.newMarkup = this.innerInsurer.newMarkup
        this.innerQuote.usedMarkup = this.innerInsurer.usedMarkup
        this.innerQuote.recoreMarkup = this.innerInsurer.recoreMarkup
        this.innerQuote.afterMarketMarkup = this.innerInsurer.afterMarketMarkup
        this.innerQuote.exchangeMarkup = this.innerInsurer.exchangeMarkup
        this.innerQuote.reconditionedMarkup = this.innerInsurer.reconditionedMarkup
        this.innerQuote.shopRate = this.innerInsurer.shopRate
        this.innerQuote.labourType = this.innerInsurer.labourType
        this.innerQuote.verRtrm = Math.max.apply(
          Math,
          this.innerInsurer.insurerConsumables.map(function (c) {
            return c.versionNo
          })
        )
        // Update quote rates
        this.addInsurerLabourRates(this.innerInsurer)
        // Add or update automatic items to quote
        this.addInsurerAutoItems(this.innerInsurer)
        // Add or update template items to quote
        this.addInsurerTemplateItems(this.innerInsurer)
      }
    },
    calculateExcess() {
      if (this.innerQuote.excessWithGst && this.excess !== this.innerQuote.excess) {
        const gstModel = new GstModel(this.innerQuote.gstRate)
        this.excess = roundAwayFromZero(this.excess / gstModel.multiplier)
        this.innerQuote.excess = this.excess
        console.log(this.innerQuote.excess)
      } else if (this.excess !== this.innerQuote.excess) {
        this.innerQuote.excess = this.excess
      }
    },
    getRate(code) {
      let rateObject = null
      if (code === ItemCategoryTypes.PAINT) {
        rateObject = this.innerQuote.rates.find((i) => i.labourCodeId === this.vehicle.paintGroup)
      } else {
        rateObject = this.innerQuote.rates.find((i) => i.labourCodeId === code)
      }
      const rate = !this.isNtar && rateObject ? rateObject.rate : this.innerQuote.shopRate
      return rate
    },
    getDamageSeverityDescription(value) {
      let desc = this.$filters.formatPascalToDescription(value)
      if (desc.toLowerCase() === 'none') {
        desc = ''
      }
      return desc
    },
    async getClaimsTypes() {
      this.claimsTypes = await CompanyService.getClaimsTypes()
    },
    onClaimsTypeChange(selected) {
      this.innerQuote.claimsTypeKey = selected.key
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
div.tile.is-parent {
  min-height: calc(100vh - 8rem);
}

.columns.field {
  margin-bottom: 0.75rem;
  .column {
    padding-bottom: 0 !important;
    &:first-child {
      padding-right: 0.375rem;
    }
    &:last-child {
      padding-left: 0.375rem;
    }
    :not(:first-child):not(:last-child) {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
    }
  }
}

.is-divider {
  margin: 0 0 1.2rem 0;
}
</style>
